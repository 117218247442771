import React, { useContext } from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { Link } from "react-router-dom";

const Modal = ({ open, setOpen, product }) => {
  console.log("product test", product);

  const cancelButtonRef = useRef(null);

  const [postImages, setPostImages] = useState([]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-100 "
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity  " />
        </Transition.Child>

        <div className="fixed inset-0  overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg   text-left shadow-xl transition-all sm:my-8 ">
                {/* akhanthake soru modal  */}
                <div className="flex min-h-full flex-col  ">
                  {/* sm:sticky sm:top-8  */}
                  <div className="sm:sticky sm:top-8">
                    <div className=" sm:mx-auto sm:w-full sm:max-w-md text-left ">
                      <div className="bg-white py-8 px-4  mb-1 md:mt-0 mt-6 shadow sm:rounded-lg sm:px-10">
                        <h1 className="text-xl font-bold uppercase mb-4 flex ">
                          Add Event{" "}
                          <span className="ml-24 text-5xl mt-1 text-sky-400 w-16">
                            <img
                              src="https://templates.iqonic.design/datum/html/assets/images/logo.png"
                              alt=""
                            />
                          </span>
                        </h1>

                        <form>
                          <div class="col-span-6 sm:col-span-3 mt-4">
                            <label
                              for="first-name"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Event Name
                            </label>
                            <input
                              type="text"
                              name="userName"
                              id="userName"
                              placeholder="Event Name"
                              //   Value={loggedInUser?.displayName}
                              
                              autocomplete="userName"
                              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm"
                            />
                          </div>







                          <div className="grid grid-cols-2 gap-2 mt-4">
                          <div class="">
                            <label
                              for="first-name"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Event Name
                            </label>
                            <input
                              type="date"
                              name="userName"
                              id="userName"
                              placeholder="Event Name"
                              //   Value={loggedInUser?.displayName}
                              
                              autocomplete="userName"
                             
                            />
                          </div>
                          <div class="">
                            <label
                              for="first-name"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Event Name
                            </label>
                            <input
                              type="date"
                              name="userName"
                              id="userName"
                              placeholder="Event Name"
                              //   Value={loggedInUser?.displayName}
                              
                              autocomplete="userName"
                             
                            />
                          </div>
                          </div>

                          <div class=" mt-4">
                            <label
                              for="last-name"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              name="email"
                              //   Value={loggedInUser?.email}
                              disabled
                              id="email"
                              autocomplete="email"
                              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="poneNumber"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Phone Number
                            </label>
                            <input
                              type="number"
                              name="phoneNumber"
                              id="poneNumber"
                              autocomplete="family-name"
                              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Phone Number"
                              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm"
                            />
                          </div>

                          <div>
                            <label
                              htmlFor="Message"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Message
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="text"
                                name="message"
                                type="text"
                                autoComplete="message"
                                placeholder="Message"
                                required
                                className="mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm "
                              />
                            </div>
                          </div>

                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <input
                                id="remember-me"
                                name="remember-me"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor="remember-me"
                                className="ml-2 block text-sm text-gray-900"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>

                          <div>
                            <p className="text-xl font-bold ml-auto mr-auto">
                              Call (088) 0162-70008
                            </p>
                            <br />
                            <button
                              type="submit"
                              className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Add Event
                            </button>
                          </div>
                        </form>

                        <div className="mt-6">
                          <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                              <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                              <span className="bg-white px-2 text-gray-500">
                                Or continue with
                              </span>
                            </div>
                          </div>

                          <div className="mt-6 grid grid-cols-3 gap-3">
                            <div>
                              <a
                                href="https://www.facebook.com/md.jonyhossain.589"
                                target="_blank"
                                rel="noreferrer"
                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                              >
                                <span className="sr-only">
                                  Sign in with Facebook
                                </span>
                                <svg
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                            </div>

                            <div>
                              <Link
                                to=""
                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                              >
                                <span className="sr-only">
                                  Sign in with Twitter
                                </span>
                                <svg
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                              </Link>
                            </div>

                            <div>
                              <a
                                href="https://github.com/Zunaid-jony"
                                target="_blank"
                                rel="noreferrer"
                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                              >
                                <span className="sr-only">
                                  Sign in with GitHub
                                </span>
                                <svg
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* img box */}
                      {/* <div>
                    <img className="mt-10 p-2" src={sideimg} alt="" />
                  </div> */}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
