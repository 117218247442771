import React, { useEffect } from "react";
import { AiFillEdit } from "react-icons/ai";
import { CiCalendarDate } from "react-icons/ci";
import { GrView } from "react-icons/gr";

import axios from "axios";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonButton } from "../../Components/Button/Button";
import { useFormik } from "formik";
import { Editor } from "primereact/editor";
import { Dialog } from "primereact/dialog";

import { toast, ToastContainer } from "react-toastify";
import { ClipboardCheckOutline } from "heroicons-react";
import { useCreateTaskMutation } from "../../redux/product/productApi";


import { Uploader } from "uploader"
import { UploadButton } from "react-uploader";
import ProductPreview from "../Products/productPreview";



const uploader = Uploader({ apiKey: "free" });
const uploaderOptions = {
  multi: false,
  styles: {
    colors: {
      primary: "#377dff"
    }
  }
}
// const options = { multi: true };

// const MyUploadButton = ({setFiles}) =>
//   <UploadButton uploader={uploader}
//                 options={uploaderOptions}
//                 onComplete={setFiles}>
               
//     {({onClick}) =>
//       <button onClick={onClick} className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300">
//         Upload a file...{image}
//       </button>
//     }
//   </UploadButton>



const MyUploadButton2 = ({setFiles}) =>
<UploadButton uploader={uploader}
                  options={uploaderOptions2}
                  onUpdate={files => 
                    console.log(`Files: ${files
                           .map(x => x.fileUrl)
                           .join("\n")}`)
                  }
                  onComplete={setFiles}
                  width="600px"
                  height="375px">
             
  {({onClick}) =>
    <button onClick={onClick} className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300">
      Upload a file...
    </button>
  }
</UploadButton>

const uploaderOptions2 = {
  multi: true,

  // Comment out this line & use 'onUpdate' instead of 
  // 'onComplete' to have the dropzone close after upload.
  showFinishButton: true,
  
  styles: {
    colors: {
      primary: "#377dff"
    }
  }
}








const ProductTest = () => {
  const {id} = useParams()
  const [displayBasic, setDisplayBasic] = useState(false);
  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  
  const [categoryId, setCategory] = useState([])

  
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 


  
  const [createTask] = useCreateTaskMutation();
  const [productName, setProductName] = useState('')
  const [description, setDescription] = useState('')
  const [shortDescription, setShortDescription] = useState('');
  const [productTag, setProductTag] = useState('');
  const [status, setStatus] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [file, setFile] = useState([]);
  const [stock, setStock] = useState('');
  const [author, setAuthor] = useState('')
 const [videoUrl, setVideoUrl] = useState('')
//  console.log('file :', file)

 
 ////
 const MyUploadButton = ({setFiles}) =>
 <UploadButton uploader={uploader}
               options={uploaderOptions}
               onComplete={setFiles}>
              
   {({onClick}) =>
     <button onClick={onClick} className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300">
       Upload a file...
     </button>
   }
 </UploadButton>

 ///
 


 
 
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
   
    try {
    
      
     
      const allData = {
        // ...values,
        category: categoryId,
        // image: imageUrl,
        image: file[0].fileUrl,
        createdDate: new Date(),
        productName: productName,
        // description: description,
        description: text,
        shortDescription: shortDescription,
        productTag: productTag,
        status:status,
        price: price,
        discount:discount,
        file: file[0].fileUrl,
        // file: file[0].fileUrl,
        // file: file.push(file[0].fileUrl),
        stock:stock,
        video: videoUrl,
        author:author
        // status: "Draft",
      };

      // createTask(allData);

      const anotherApiResponse = await axios.post(
        
        `https://primeautomationapiapi.vercel.app/project`,
       
        allData
      );
      // console.log(anotherApiResponse);
      navigate("/allPost");
      toast.success('Project add successfully.');
    } catch (error) {
      setLoading(false);
    }
  };

 


  const handleTextChange = (e) => {
    setText( e.textValue);
  };


  const handleTextDescription  = (e) => {
    setShortDescription( e.textValue);
  };

/////
  const [card, setCard] = useState([]);

  

  const ProductData = async (value) => {
    try {
      setLoading(true);
      await axios
        .get(
          
          
          // `http://localhost:5000/category`
          `https://primeautomationapiapi.vercel.app/project-category`
          
        )
        .then(function (res) {
          setLoading(false);
          setCard(res?.data);

          // console.log(res?.data);
        })
        .catch(function (error) {
          toast.error(error);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  useEffect(() => {
    ProductData();
  }, []);


 

  ////
  return (
    <form className="bg-white p-6 rounded-lg" onSubmit={(e) => handleSubmit(e)}>
      <div className="bg-[#f0f0f1]">
        <div className="container ml-auto mr-auto grid grid-cols-12 pl-4 gap-4">
          <div className="col-span-9">
            <div className="mt-3 text-left mb-4">
              <label
                className="block mb-2 text-sm font-medium"
                for="productName"
                placeholder="Inter Your Email"
              >
                Add new Project
              </label>
              <input
                id="productName"
                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                type="text"
                name="productName"
               
                onChange={(e) => setProductName(e.target.value)}
               
                // required
              />
            </div>
           



            <div className="mt-3 text-left mb-4">
              <label
                className="block mb-2 text-sm font-medium"
                for="productName"
                placeholder="Inter Your Email"
              >
                Description
              </label>
              <div className="card">
              <Editor
          
                value={text}
                
                onTextChange={handleTextChange}
                style={{ height: "120px" }}
              />
            </div>
            </div>
           

           
            <br />
            <br />
          
            
            <div className="mt-3 text-left mb-4">
              <label
                className="block mb-2 text-sm font-medium"
                for="productName"
                placeholder="Inter Your Email"
              >
               Short Description
              </label>
              <div className="card">
              <Editor
                
                value={shortDescription}
                // className='card'
                onTextChange={handleTextDescription}
                style={{ height: "120px" }}
              />
            </div>
            </div>

            {/* dopd */}

            <div class="col-span-6 sm:col-span-3">
              <label
                for="country"
                class="block text-sm font-medium text-gray-700 text-left"
              >
                Add New Category
              </label>




              <select 
                id="category"
                name="category"
                autocomplete="category"
                
                class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                //  onChange={handleCategory}
                onChange={(e) => setCategory(e.target.value)}
                
                
                 >
                              <option>Select Category </option>
                              {
                              card.map(c => (
                                <>
                                {/* <option>Select Category </option> */}
                                 <option value={c._id} key={c._id}>
                     
                                    {c.productName}
                                 </option>
                                </>
                              ))
                             }
                          </select>





 
            </div> 

             

          
          </div>

          <div className="col-span-3  mt-11">
            <div className="border-2 border-gray-100 w-full p-2 bg-white">
              <p className="p-2 w-full text-left"></p>
              <hr />
              <div className="flex justify-between mt-2">
              <div class="col-span-6 sm:col-span-3 mb-2">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700 text-left"
                >
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  autocomplete="status"
                  
                  onChange={(e) => setStatus(e.target.value)}
                  class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                  <option 
                  // value={formik.values.status}
                  
                  >Select Category</option>
                  <option value="Draft">Draft</option>
                  <option value="Active">Active</option>
                  {/* status: "Draft", */}
                </select>
              </div>














             
               <button class="bg-gray-300 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
               
               onClick={() => {
                onClick("displayBasic");
              }}
               >
                  Preview
                </button>
             
               
              </div>
              <p className="mt-2 text-left flex">
                {" "}
                <AiFillEdit className="mr-2 mt-1"></AiFillEdit> Status: Draft
                Edit
              </p>
              <p className="mt-2 text-left flex">
                {" "}
                <GrView className="mr-2 mt-1"></GrView> Visibility: Public Edit
              </p>
              <p className="mt-2 text-left flex">
                {" "}
                <CiCalendarDate className="mr-2 mt-1"></CiCalendarDate> Publish
                immediately Edit
              </p>
              <p className="mt-2 text-left flex">
                {" "}
                <CiCalendarDate className="mr-2 mt-1"></CiCalendarDate>Catalog
                visibility: Shop and search results Edit
              </p>
              <hr />
              <div className="flex justify-between">
                <p></p>
                {/* <button class="bg-gray-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 ml-auto">
                  Save Publish
                </button> */}

                <CommonButton
                  className="bg-gray-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 ml-auto"
                  title="Save"
                  disabled={false}
                  label="Save Publish"
                  type="submit"
                  icon="pi pi-save"
                  color="p-button-raised p-button-success"
                  loading={loading}
                />
              </div>
            </div>
            <div className="border-2 border-gray-100 w-full p-2 bg-white mt-6">
              <p className="p-2 w-full text-left">Project Images </p>
              <hr />

            

              <hr />


              <MyUploadButton  setFiles={setImage}  />

            </div>

           

           {/* // */}
           <div className="border-2 border-gray-100 w-full p-2 bg-white mt-6">
              <p className="p-2 w-full text-left">Project Gallery </p>
              <hr />



<MyUploadButton2  setFiles={setFile}  />
              <hr />




            </div>


{/* //// */}
{/* <div className="border-2 border-gray-100 w-full p-2 bg-white mt-6">
              <p className="p-2 w-full text-left">Price </p>
              <hr />

            

              <hr />



              <p>Enter URL of Youtube or Vimeo or  </p> 
              <p>specific filetypes. Such as mp4,webm,ogv </p>


<div className="mt-4 text-left">
                <label
                  className="block mb-2 text-sm font-medium text-black dark:text-black"
                  for="price"
                >
                  
                </label>
                <input
                  id="price"
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                  type="text"
                  name="price"
                  onChange={(e) => setPrice(e.target.value)}
                  // required
                  placeholder="Enter Price"
                />
              </div>


              <div className="mt-4 text-left">
                <label
                  className="block mb-2 text-sm font-medium text-black dark:text-black"
                  for="discount"
                >
                  Author
                </label>
                <input
                  id="author"
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                  type="text"
                  name="author"
                  onChange={(e) => setAuthor(e.target.value)}
                  // required
                  placeholder="Enter Author"
                />
              </div>
            </div> */}

            {/* product Tag */}

            <div className="border-2 border-gray-100 w-full p-2 bg-white mt-6">
              <p className="p-2 w-full text-left">Project  </p>
              <hr />
              <div className="mt-4 text-left mb-4">
                <label
                  className="block mb-2 text-sm font-medium "
                  for="productTag"
                  placeholder="Inter Your Email"
                >
                  Add project tag
                </label>
                <input
                  id="productTag"
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                  type="text"
                  name="productTag"
                  // onChange={formik.handleChange}
                  // value={formik.values.productTag}
                  onChange={(e) => setProductTag(e.target.value)}
                 
                />
              </div>

              

              <hr />
              {/* <div className="mt-2 ">
                <CommonButton
                  className="p-mr-2 p-button-raised p-button-secondary"
                  title="Save"
                  disabled={false}
                  label="Save"
                  type="submit"
                  icon="pi pi-save"
                  color="p-button-raised p-button-success"
                  loading={loading}
                />
              </div> */}
            </div>
          </div>
        </div>

        <Dialog
        className="text-l"
        blockScroll
        header="Preview"
        visible={displayBasic}
        style={{ width: "60vw" }}
        onHide={() => onHide("displayBasic")}
        id="fname"
        maximizable
      >
        <ProductPreview categoryId={categoryId} image={image} productName={productName} text={text}
          shortDescription={shortDescription} productTag={productTag} status={status} price={price}
          discount={discount} file={file} stock={stock} videoUrl={videoUrl}
        />
      </Dialog>


    
      <ToastContainer />
      </div>
    </form>
  );
};

export default ProductTest;
