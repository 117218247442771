import React from 'react'
import { Link } from 'react-router-dom'


const ProductPreview = ({categoryId, image, productName ,text,shortDescription,  productTag, status,price,discount,file,stock, videoUrl }) => {
  return (
    <div>
    <h1> Category Id: {categoryId}</h1> <br />
    <h1>Image: </h1>
    <img src={image} alt='the' />
    <h1>Product Name: {productName}</h1>  <br />
    <h1>Description: {text}</h1> <br />
    <h1>Short Description: {shortDescription}</h1> <br />
    <h1>Product Tag: {productTag}</h1> <br />
    <h1>Status: {status}</h1> <br />
    <h1>Video: {videoUrl}</h1> <br />
    </div>
  )
}

export default ProductPreview