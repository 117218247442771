import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";



export const projectServerApi = createApi({
  reducerPath: 'projectServerApi',
  baseQuery: fetchBaseQuery({baseUrl: 'https://primeautomationapiapi.vercel.app/project'}),
  tagTypes: ['Task'],
  endpoints: (builder) => ({
    getTask: builder.query({
      query: () => `/`,
      providesTags: ['Task'],
    }),
    createTask: builder.mutation({
      query: (data) => ({
        url: `/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Task']
    }),

    
  }),
})
// })


export const {useGetTaskQuery, useCreateTaskMutation} = projectServerApi


