import React from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { AiFillEdit } from "react-icons/ai";
import { CiCalendarDate } from "react-icons/ci";
import { GrView } from "react-icons/gr";

import axios from "axios";

import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import { CommonButton } from "../../../Components/Button/Button";
import { Editor } from "primereact/editor";
import { Dialog } from "primereact/dialog";

import { Uploader } from "uploader"
import { UploadButton } from "react-uploader";
const uploader = Uploader({ apiKey: "free" });
const uploaderOptions = {
  multi: false,
  styles: {
    colors: {
      primary: "#377dff"
    }
  }
}


const MyUploadButton = ({setFiles}) =>
  <UploadButton uploader={uploader}
                options={uploaderOptions}
                onComplete={setFiles}>
    {({onClick}) =>
      <button onClick={onClick} className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300">
        Upload a file...
      </button>
    }
  </UploadButton>


const MyUploadButton2 = ({setFiles}) =>
<UploadButton uploader={uploader}
                  options={uploaderOptions2}
                  onUpdate={files => 
                    console.log(`Files: ${files
                           .map(x => x.fileUrl)
                           .join("\n")}`)
                  }
                  onComplete={setFiles}
                  width="600px"
                  height="375px">
             
  {({onClick}) =>
    <button onClick={onClick} className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300">
      Upload a file...
    </button>
  }
</UploadButton>

const uploaderOptions2 = {
  multi: true,

  // Comment out this line & use 'onUpdate' instead of 
  // 'onComplete' to have the dropzone close after upload.
  showFinishButton: true,
  
  styles: {
    colors: {
      primary: "#377dff"
    }
  }
}


const EditProject = () => {
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // click to on other page
  const [product, setProduct] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [card, setCard] = useState([]);
  const [file, setFile] = useState([])

  const [displayBasic, setDisplayBasic] = useState(false);
  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };


  useEffect(() => {
    fetch("https://primeautomationapiapi.vercel.app/project")
      .then((res) => res.json())
      .then((data) => setAllProduct(data));
  }, []);
  // console.log("All----Edit", allProduct);
  // console.log("All----Edit", product?._id);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();

  useEffect(() => {
    const getProducts = () => {
      setProduct(allProduct?.find((obj) => obj._id == id));
    };
    if (allProduct?.length > 0) {
      getProducts();
    }
  }, [allProduct, id]);
  // console.log("Product", product);

  const formik = useFormik({
    initialValues: {
      productName: product?.productName,
      description: product?.description,
      shortDescription: product?.shortDescription,
      productTag: product?.productTag,
      category: product?.category,
      status: product?.status,
      price: '',
      discount:'',
      image: '',
      file: '',
      createdDate: '',
      stock:'',
      video: videoUrl
    },
    onSubmit: (values, { resetForm }) => {
      // console.log(values);
      handleSubmit(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });
  // const apiKey = "f633b9b2b900fa4ce91d346d6b992734";
  const handleSubmit = async (values) => {
    setLoading(true);
    // const url = "https://api.imgbb.com/1/upload";
    // const formData = new FormData();
    // formData.append("image", image);
    // formData.append("image1", image1);
    // formData.append("key", apiKey);
    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // };

    try {
      // const imgbbResponse = await axios.post(url, formData, config);
      // const imageUrl = imgbbResponse.data.data.url;
      // const imageUrl1 = imgbbResponse.data.data.url;
      const allData = {
        ...values,
        image: file[0].fileUrl,
        createdDate: new Date(),
        // upsertedId:product?._id,
      };
      const anotherApiResponse = await axios.patch(
       
        `https://primeautomationapiapi.vercel.app/project/${product._id}`,
       
        allData
      );
      console.log(anotherApiResponse);
      navigate("/allPost");
    } catch (error) {
      setLoading(false);
    }
  };
 
  // const handleImage = (e) => {
  //   setImage(e.target.files[0]);
  // };
  // const handleImage1 = (e) => {
  //   setImage1(e.target.files[0]);
  // };

  const ProductData = async (value) => {
    try {
      setLoading(true);
      await axios
        .get(
          
          
          // `http://localhost:5000/category`
          `https://primeautomationapiapi.vercel.app/project-category`
          
        )
        .then(function (res) {
          setLoading(false);
          setCard(res?.data);

          // console.log(res?.data);
        })
        .catch(function (error) {
          toast.error(error);
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  useEffect(() => {
    ProductData();
  }, []);
  return (
    <div>
      <form className="bg-white p-6 rounded-lg" onSubmit={formik.handleSubmit}>
        <div className="bg-[#f0f0f1]">
          <div className="container ml-auto mr-auto grid grid-cols-12 pl-4 gap-4">
            <div className="col-span-9">
              <div className="mt-3 text-left mb-4">
                <label
                  className="block mb-2 text-sm font-medium"
                  for="productName"
                  placeholder="Inter Your Email"
                >
                  Update new Project
                </label>
                <input
                  id="productName"
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                  type="text"
                  name="productName"
                  onChange={formik.handleChange}
                  value={formik.values?.productName}
                  
                  
                />
              </div>
              {/* <div className="mt-3 text-left mb-8">
                <label
                  className="block mb-2 text-sm font-medium"
                  for="description"
                  placeholder="Inter Your Email"
                >
                  Project description
                </label>
                <textarea
                  id="description"
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                  type="text"
                  name="description"
                  onChange={formik?.handleChange}
                  value={formik.values?.description}
                 
                  // required
                />
              </div> */}

              <div className="mt-3 text-left mb-4">
              <label
                className="block mb-2 text-sm font-medium"
                for="productName"
                placeholder="Inter Your Email"
              >
                Description
              </label>
              <div className="card">
              <Editor
          
                value={formik.values?.description}
                
                onTextChange={(e) => e.textValue}
                style={{ height: "120px" }}
              />
            </div>
            </div>
           


              <div className="mt-3 text-left mb-8">
                <label
                  className="block mb-2 text-sm font-medium "
                  for="shortDescription"
                  placeholder="Inter Your Email"
                >
                  Project short description
                </label>
                <Editor
          
                value={formik.values?.shortDescription}
                
                onTextChange={(e) => e.textValue}
                style={{ height: "120px" }}
              />
              </div>

              {/* dopd */}

              <div class="col-span-6 sm:col-span-3 mb-2">
                <label
                  for="country"
                  class="block text-sm font-medium  text-left"
                >
                  Status
                </label>
                <select
                  id="status"
                  name="status"
                  autocomplete="status"
                  onChange={formik.handleChange}
                  
                  class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                  <option value={formik.values?.status}>Select Category</option>
                  <option value="Draft">Draft</option>
                  <option value="Active">Active</option>
                  {/* status: "Draft", */}
                </select>
              </div>

              <div class="col-span-6 sm:col-span-3">
                <label
                  for="country"
                  class="block text-sm font-medium  text-left"
                >
                  Update New Category
                </label>
                <select
                  id="category"
                  name="category"
                  autocomplete="category"
                  onChange={formik.handleChange}
                  class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                   <option>Select Category </option>
                              {
                              card.map(c => (
                                <>
                                {/* <option>Select Category </option> */}
                                 <option value={c._id} key={c._id}>
                     
                                    {c.productName}
                                 </option>
                                </>
                              ))
                             }
                </select>
              </div>

              
            </div>

            <div className="col-span-3  mt-11">
              <div className="border-2 border-gray-100 w-full p-2 bg-white">
                <p className="p-2 w-full text-left">Publish</p>
                <hr />
                <div className="flex justify-between mt-2">
                  <button class="bg-gray-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Save Draft
                  </button>
                  {/* <button class="bg-gray-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Preview
                  </button> */}
                   <button class="bg-gray-300 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
               
               onClick={() => {
                onClick("displayBasic");
              }}
               >
                  Preview
                </button>
                </div>
                <p className="mt-2 text-left flex">
                  {" "}
                  <AiFillEdit className="mr-2 mt-1"></AiFillEdit> Status: Draft
                  Edit
                </p>
                <p className="mt-2 text-left flex">
                  {" "}
                  <GrView className="mr-2 mt-1"></GrView> Visibility: Public
                  Edit
                </p>
                <p className="mt-2 text-left flex">
                  {" "}
                  <CiCalendarDate className="mr-2 mt-1"></CiCalendarDate>{" "}
                  Publish immediately Edit
                </p>
                <p className="mt-2 text-left flex">
                  {" "}
                  <CiCalendarDate className="mr-2 mt-1"></CiCalendarDate>Catalog
                  visibility: Shop and search results Edit
                </p>
                <hr />
                <div className="flex justify-between">
                  <p></p>
                  <button class="bg-gray-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 ml-auto">
                    Save Draft
                  </button>
                </div>
              </div>
              <div className="border-2 border-gray-100 w-full p-2 bg-white mt-6">
                <p className="p-2 w-full text-left">Project Images </p>
                <hr />

                <div className="mt-4">
                  <div className="flex justify-between">
                    <label
                      className="block mb-2 text-sm font-medium text-black dark:text-black"
                      for="file1"
                    >
                      {/* Select Image */}
                    </label>
                  </div>

                  {/* <input
                    id="file1"
                    className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                    type="file"
                    name="file1"
                    onChange={handleImage}
                    placeholder="file"
                  /> */}
                   <MyUploadButton  setFiles={setImage}  />
                </div>

                <hr />
              </div>

              <div className="border-2 border-gray-100 w-full p-2 bg-white mt-6">
              <p className="p-2 w-full text-left">Project Gallery </p>
              <hr />

              <div className="mt-4">
                <div className="flex justify-between">
                  <label
                    className="block mb-2 text-sm font-medium text-black dark:text-black"
                    for="file1"
                  >
                    
                  </label>
                </div>

                {/* <input
                  id="file1"
                  className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                  type="file"
                  name="file1"
                  
                  placeholder="file"
                /> */}
                <MyUploadButton2  setFiles={setFile}  />
              </div>

              <hr />




            </div>


           

              {/* product Tag */}

              <div className="border-2 border-gray-100 w-full p-2 bg-white mt-6">
                <p className="p-2 w-full text-left">Project Tags </p>
                <hr />
                <div className="mt-4 text-left mb-4">
                  <label
                    className="block mb-2 text-sm font-medium "
                    for="productTag"
                    placeholder="Inter Your Email"
                  >
                    Update Project tag
                  </label>
                  <input
                    id="productTag"
                    className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                    type="text"
                    name="productTag"
                    onChange={formik.handleChange}
                    value={formik.values?.productTag}
                    // defaultValue={product?.productTag}
                   
                  />
                </div>

           

                <Dialog
        className="text-l"
        blockScroll
        header="Preview"
        visible={displayBasic}
        style={{ width: "60vw" }}
        onHide={() => onHide("displayBasic")}
        id="fname"
        maximizable
      >
         <div>
    <h1> Category Id: {product?._id}</h1> <br />
    {/* <h1>Image: {product.image}</h1>
     */}
     <img src={product?.image} alt='the' height={40} width={200} />
    <h1>Project Name: {product?.productName}</h1>  <br />
    <h1>Description: {product?.text}</h1> <br />
    <h1>Short Description: {product?.shortDescription}</h1> <br />
    <h1>Project Tag: {product?.productTag}</h1> <br />
    <h1>Status: {product?.status}</h1> <br />
    
    </div>
      </Dialog>

            

                <hr />
                <div className="mt-2 ">
                  <CommonButton
                    className="p-mr-2 p-button-raised p-button-secondary"
                    title="Save"
                    disabled={false}
                    label="Save"
                    type="submit"
                    icon="pi pi-save"
                    color="p-button-raised p-button-success"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProject;
