import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { taskServerApi } from './product/productApi';
import { projectServerApi } from './project/projectApi';

export const store = configureStore({
  reducer: {
   
    [taskServerApi.reducerPath] : taskServerApi.reducer,
    [projectServerApi.reducerPath] : projectServerApi.reducer,
    
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().concat( taskServerApi.middleware).concat( projectServerApi.middleware)
    
  )
   
});

setupListeners(store.dispatch);