import React from "react";

const Bannar = () => {
  return (
    <div>
      <div className=' ml-auto mr-auto section section-work-data bg-[url("https://cdn.pixabay.com/photo/2022/06/20/14/20/space-7273891_960_720.jpg")]'>
        <div className="ml-auto   text-white    pt-20 pb-20 ">

          <p className="">ALL PRODUCT</p>
          <p className="text-xl"> <span className="mr-1">Home </span>  > <span className="ml-1">Product</span> </p>

          
        </div>
      </div>
    </div>
  );
};

export default Bannar;
